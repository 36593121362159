<template>
  <b10-confirm-dialog
    :showing.sync="internalShowing"
  >
    <template
      slot="cardText"
    >
      <div
        v-if="mensaje"
      >
        {{ mensaje }}
      </div>
      <div
        v-else
      >
        ¿Deseas eliminar el artículo {{ descripcion }} del parte de trabajo?
      </div>
      <v-switch
        v-model="deleteOT"
        label="Quitar también de la orden de trabajo"
        messages="El material nunca se va a instalar"
      />
    </template>
    <template
      slot="cardActions"
    >
      <v-btn
        text
        @click.stop="clickCancelar"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        @click.stop="clickAceptar"
      >
        Aceptar
      </v-btn>
    </template>
  </b10-confirm-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
// TODO: Cambiar nombre a este componente, se está usando desde: LparteTrabajoList.vue, LparteTrabajoView.vue, LsolicitudMaterialList.vue

export default {
  mixins: [internalShowingMixin],
  props: {
    descripcion: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => {},
    },
    mensaje: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      deleteOT: false,
    }
  },
  methods: {
    clickCancelar () {
      this.internalShowing = false
    },
    clickAceptar () {
      this.internalShowing = false
      this.$emit('delete', { ...this.data, deleteOT: this.deleteOT })
    },
  },
}
</script>
